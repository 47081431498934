<template>
  <apexcharts
    width="98%"
    height="70"
    type="bar"
    :options="chartOptions"
    :series="series"
    class="line-chart" />
</template>

<script>
  import widgetsMixin from '@/mixins/widgets'

  export default {
    mixins: [ widgetsMixin ],
    props: {
      series: Array,
      labels: Array,
      colors: Array,
      chartName: String
    },
    data () {
      return {
        chartColors: [],
        chartOptions: {
          chart: {
            stacked: true,
            stackType: '100%',
            toolbar: { show: false }
          },
          plotOptions: {
            bar: {
              barHeight: '55%',
              horizontal: true,
              dataLabels: { hideOverflowingLabels: false }
            }
          },
          stroke: {
            width: 1,
            colors: ['#fff']
          },
          title: { text: undefined },
          xaxis: {
            categories: [this.chartName],
            labels: { show: false },
            axisBorder: { show: false }
          },
          yaxis: {
            title: { text: undefined },
            labels: { show: false },
            axisBorder: { show: false }
          },
          grid: {
            show: true,
            borderColor: '#fff',
            xaxis: { lines: { show: false } },
            yaxis: { lines: { show: false } },
            padding: { top: -40, right: 0, bottom: 0, left: 0 }
          },
          fill: { opacity: 1 },
          dataLabels: {
            style: { fontSize: '14px' },
            dropShadow: {
              enabled: true,
              top: 1,
              left: 1,
              blur: 1,
              opacity: 0.25
            }
          },
          legend: {
            position: 'top',
            horizontalAlign: 'left',
            offsetX: -30,
            offsetY: 10,
            markers: { radius: 15 },
            itemMargin: { vertical: 10 }
          }
        }
      }
    },
    beforeMount () {
      if (!this.colors) {
        this.chartOptions.colors = [
          this.$vuetify.theme.themes.light.primary,
          this.$vuetify.theme.themes.light.accent,
          this.$vuetify.theme.themes.light.secondary
        ]
      } else {
        const isMonochrome = this.colors.length === 1
        this.chartOptions.colors = this.colors
        if (isMonochrome) {
          this.chartOptions.theme = {
            monochrome: { enabled: isMonochrome, color: this.colors[0] }
          }
        }
      }
    }
  }
</script>

<style lang="sass">
  .line-chart
    .apexcharts-inner
      transform: translate(0, 35px)

    .apexcharts-xaxis-tick
      display: none

    .apexcharts-legend-series
      display: flex !important

    .apexcharts-legend-marker
      margin-right: 5px
</style>
