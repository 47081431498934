<template>
  <div class="container-page">
    <template>
      <v-card
        class="transparent card-pt-0"
        flat>
        <v-card-title>
          <v-flex
            v-if="hasChart"
            xs12
            sm5>
            <widget-line-chart
              :series="series"
              :labels="labels"
              :colors="colors"
              :chart-name="chartName" />
          </v-flex>

          <v-flex
            v-if="hasFilter && stateList.length > 0"
            xs12
            sm3>
            <v-select
              v-model="pagination.state"
              class="select-state pr-6 reset-padding-xs"
              :items="stateList"
              item-value="value"
              item-text="label"
              label="Filtrar por status"
              single-line />
          </v-flex>

          <v-flex
            v-if="hasSearch"
            xs12
            sm4>
            <v-text-field
              v-model="pagination.search"
              label="Pesquisar"
              :prepend-icon="$root.icons.layout.search"
              :append-icon="pagination.search.length > 0 ? 'close' : ''"
              single-line
              hide-details
              @click:append="clearSearch" />
          </v-flex>

          <slot
            v-else-if="hasCustomFilter"
            right
            name="customFilter" />
        </v-card-title>
      </v-card>

      <loading
        v-if="loadingList"
        class="absolute-center" />

      <div
        v-if="isRender && loadingList === 0"
        class="px-0 py-0">
        <v-card
          v-if="totalEntries > 0"
          class="transparent elevation-0 card-pt-0">
          <v-data-table
            :class="dataTableClass + ' table-sticky transparent'"
            :headers="headers"
            :options.sync="paginate"
            :items="entries"
            :search="pagination.search"
            :footer-props="footerProps"
            :server-items-length="totalEntries"
            :hide-default-footer="hideFooter">
            <template
              v-slot:item="props"
              class="transparent">
              <slot
                name="row"
                :props="props" />
            </template>

            <template v-slot:footer.page-text="props">
              Exibindo de {{ props.pageStart }} a {{ props.pageStop }} no total de {{ props.itemsLength }}
            </template>

            <empty-state
              slot="no-results"
              class="mt-2"
              size="75px"
              :icon="$root.icons.state.no_result">
              Sua busca por "{{ pagination.search }}" não possui resultados
            </empty-state>
          </v-data-table>
        </v-card>
        <div v-else>
          <empty-state
            v-if="pagination.state !== '' || pagination.search !== ''"
            size="75px"
            :icon="$root.icons.link.cases"
            class="absolute-center">
            Até o momento, não há casos registrados com esse resultado
          </empty-state>

          <slot v-else name="empty" />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
  import EmptyState from '@/components/ui/EmptyState'
  import Loading from '@/components/ui/Loading'
  import WidgetLineChart from '@/components/ui/widgets/WidgetLineChart'

  export default {
    components: { EmptyState, Loading, WidgetLineChart },
    props: {
      totalEntries: Number,
      series: Array,
      labels: Array,
      colors: Array,
      chartName: String,
      entries: {
        type: Array,
        default: () => []
      },
      headers: Array,
      pagination: {
        type: Object,
        default: () => ({
          search: '',
          rowsPerPageItems: [10, 20, 50, 100]
        })
      },
      stateList: {
        type: Array,
        default: () => []
      },
      loadingList: {
        type: Number,
        default: 0
      },
      isRender: {
        type: Boolean,
        default: false
      },
      hasSearch: {
        type: Boolean,
        default: false
      },
      hasCustomFilter: {
        type: Boolean,
        default: false
      },
      hasFilter: {
        type: Boolean,
        default: false
      },
      hasChart: {
        type: Boolean,
        default: false
      },
      hideFooter: {
        type: Boolean,
        default: false
      },
      dataTableClass: {
        type: String,
        default: ''
      }
    },
    data () {
      return {
        messageModal: '',
        showModal: false,
        paginate: {}
      }
    },
    watch: {
      paginate: {
        handler (paginate) {
          this.$emit('paginate', paginate)
        },
        deep: true
      }
    },
    beforeMount () {
      this.paginate = this.pagination
      this.footerProps = {
        itemsPerPageOptions: this.pagination.rowsPerPageItems,
        rowsPerPageText: 'Mostrar por página'
      }
    },
    methods: {
      clearSearch () {
        this.pagination.search = ''
      }
    }
  }
</script>

<style lang="sass">
  .v-align-center.icon
    vertical-align: bottom

  .select-state
    position: relative
    top: 12px

  .hideFooterSelect
    .v-data-footer__select
      display: none

    .v-data-footer__pagination
      margin: 18px 32px 18px 24px
</style>
